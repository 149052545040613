<template>
  <SidePopup
    :title="title"
    @close="onClose"
    routeback="/fornecedor/comercial/cupom-desconto"
  >
    <Grid grid-template-columns="repeat(3, 1fr)" grid-gap="20px">
      <div class="info-show md">
        <div class="info-label">Código do cupom</div>
        <div class="info-value">{{ cupom.codigocupom }}</div>
      </div>
      <div class="info-show md">
        <div class="info-label">Origem</div>
        <div class="info-value" v-if="cupom.tipo === 1">Indicação</div>
        <div class="info-value" v-if="cupom.tipo === 2">Recompensa</div>
        <div class="info-value" v-if="cupom.tipo === 4">Cadastro</div>
      </div>
      <div class="info-show md">
        <div class="info-label">Desconto</div>
        <div class="info-value" v-if="cupom.tipodesconto === '%'">
          {{ cupom.valordesconto }}{{ cupom.tipodesconto }}
        </div>
        <div
          class="info-value"
          v-if="cupom.tipodesconto === 'R$' || cupom.tipodesconto === '$'"
        >
          R$ {{ cupom.valordesconto }}
        </div>
      </div>
      <div class="info-show md">
        <div class="info-label">Data inicio</div>
        <div class="info-value">{{ cupom.datainicio | formatDate }}</div>
      </div>
      <div class="info-show md">
        <div class="info-label">Data fim</div>
        <div class="info-value" v-if="cupom.tipo === 4">
          {{ cupom.datafim | formatDate }}
        </div>
        <div class="info-value" v-if="cupom.tipo === 1">
          {{ 'Não expira' }}
        </div>
      </div>
      <div class="info-show md">
        <div class="info-label">Usos</div>
        <div class="info-value">{{ cupom.qtd_max_por_cliente }}</div>
      </div>
      <div class="info-show md">
        <div class="info-label">Utilizados</div>
        <div class="info-value">
          {{ cupom.qtd_uso }}/{{ cupom.qtd_max_uso || '-' }}
        </div>
      </div>
      <div class="info-show md" v-if="cupom.valor_min_pedido">
        <div class="info-label">Valor mínimo do pedido</div>
        <div class="info-value">
          {{ cupom.valor_min_pedido | formatMoney }}
        </div>
      </div>
      <div class="info-show md" v-if="cupom.valor_max_pedido">
        <div class="info-label">Valor máximo do pedido</div>
        <div class="info-value">
          {{ cupom.valor_max_pedido | formatMoney }}
        </div>
      </div>
      <div class="info-show md" v-if="cupom.valor_min_pedido_categoria">
        <div class="info-label">Valor mínimo de pedidos da categoria</div>
        <div class="info-value">
          {{
            parseFloat(cupom.valor_min_pedido_categoria).toFixed(2)
              | formatMoney
          }}
        </div>
      </div>
      <div class="info-show md" v-if="cupom.valor_max_pedido_categoria">
        <div class="info-label">Valor máximo de pedidos da categoria</div>
        <div class="info-value">
          {{
            parseFloat(cupom.valor_max_pedido_categoria).toFixed(2)
              | formatMoney
          }}
        </div>
      </div>
      <div class="info-show md">
        <div class="info-label">Usa em promoções</div>
        <div class="info-value" v-if="cupom.nao_usar_com_politica">Não</div>
        <div class="info-value" v-if="!cupom.nao_usar_com_politica">Sim</div>
      </div>
      <div class="info-show md">
        <div class="info-label">Canais de venda</div>
        <div class="info-value">{{ canaisDeVenda }}</div>
      </div>
      <div class="info-show md">
        <div class="info-label">Clientes</div>
        <div class="info-value">Qualquer cliente</div>
      </div>
      <div class="info-show md">
        <div class="info-label">Categoria</div>
        <div class="info-value">{{ cupom.nome_setor }}</div>
      </div>
      <div class="info-show md">
        <div class="info-label">Segmento do cliente</div>
        <div class="info-value">{{ cupom.nome_segmento }}</div>
      </div>
      <div class="info-show md">
        <div class="info-label">Observações</div>
        <div class="info-value">{{ cupom.obs }}</div>
      </div>
      <div class="info-show md">
        <div class="info-label">Visualização de desconto</div>
        <div class="info-value">
          {{
            cupom.habilitar_visualizacao_desconto
              ? 'Habilitado'
              : 'Desabilitado'
          }}
        </div>
      </div>
    </Grid>
    <template v-if="cupom.clientes && cupom.clientes.length > 0">
      <LineDivider position="bottom">
        <h4>Clientes</h4>
      </LineDivider>

      <DataTable
        :headers="headers"
        :endpoint="endpoint"
        :data="cupom.clientes"
        :items-length="cupom.clientes.length"
        sortBy="nome"
      >
        <template v-slot:descritor="{ col }">
          <span class="variable-descriptor">{{ col }}</span>
        </template>
      </DataTable>
    </template>
  </SidePopup>
</template>

<script>
import SidePopup from '@/components/SidePopup'
import { CUPOM_DESCONTO_LOAD } from '@/store/actions/cupom'
import DataTable from '@/components/DataTable'
import LineDivider from '@/components/LineDivider'
import Grid from '@/components/Grid'
export default {
  name: 'CupomView',
  components: { SidePopup, DataTable, LineDivider, Grid },
  data: () => ({
    cupom: {
      clientes: [],
      codigocupom: '',
      valorDesconto: '',
      datainicio: '',
      datafim: '',
      obs: '',
      tipoCupom: '',
      qtd_max_uso: null,
      tipodesconto: '%',
      qtd_uso: 0,
      qtd_max_por_cliente: 1,
      nao_usar_com_politica: false,
      valordesconto: 0,
      vencido: false
    },
    headers: [
      {
        text: 'CPF/CNPJ',
        value: 'cpf',
        sortable: false,
        clickable: false
      },
      {
        text: 'Nome',
        value: 'nome',
        sortable: false,
        clickable: false
      },
      {
        text: 'E-mail',
        value: 'email',
        sortable: false,
        clickable: false
      }
    ],
    title: 'Detalhes cupom de desconto'
  }),
  computed: {
    endpoint() {
      return `/api/v2/cupom-desconto/idcupom/${this.$route.params.id}/clientes`
    },
    canaisDeVenda() {
      return (this.cupom.device || [])
        .join(',')
        .replace('APP', 'Aplicativo')
        .replace('WEB', 'Desktop')
        .replace('MANUAL', 'Venda+')
    }
  },
  created() {
    this.getData(this.$route.params.id)
  },
  methods: {
    getData(idcupom) {
      this.$store.dispatch(CUPOM_DESCONTO_LOAD, idcupom).then(resp => {
        this.cupom = {
          ...resp
        }
      })
    },
    formatReal(v = '0') {
      return `${parseFloat(v).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })}`
    },
    onClose() {
      this.$emit('close')
    }
  }
}
</script>
