<template>
  <div
    :class="['grid', className]"
    :style="{
      'grid-template-columns': gridTemplateColumns,
      'grid-gap': gridGap
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Grid',
  props: {
    className: {
      type: String,
      required: false
    },
    gridTemplateColumns: {
      type: String,
      default: 'repeat(2, 1fr)',
      required: false
    },
    gridGap: {
      type: String,
      default: '10px',
      required: false
    }
  },
  computed: {
    ...mapGetters(['darkEnabled'])
  },
  data: () => ({
    model: 0
  }),
  created() {
    this.model = this.start
  }
}
</script>

<style lang="scss" scoped>
.grid {
  display: grid;
}
@media (max-width: 768px) {
  .grid {
    display: block;
  }
}
</style>
